export const environment = {
  production: true,
  client_id: '60uqkk64khjt8gl86fbmncfik5',
  aws_cognito_identity_pool_id: 'ap-south-1:a9e39975-7b51-400c-b7af-300088a8b0b8',
  aws_cognito_region: 'ap-south-1',
  aws_user_pools_id: 'ap-south-1_QWJHApOCI',
  domain: ".globalhuntindia.com",
  redirect_uri: "https://refcheck.globalhuntindia.com/",
  socialRedirect_URI: "signin.globalhuntindia.com",
  response_type: 'code',
  refreshtokenKey: "kf5TSFZ3gW1Q9UKQhlXZm4wwbLK4NjPR1fswlrwz",
  /* api urls */
  tokenEndPoint: "https://signin.globalhuntindia.com/",
  refreshToken: "https://apis.globalhuntindia.com/ref",
  apiURl: "https://apis.globalhuntindia.com/rcs/",
  urlSource: "source=Ref-Check",
  atsJoburl: "https://apis.globalhuntindia.com/cds/",
  attachedCandidate: "https://apis.globalhuntindia.com/ces/",
  candidateUrl: 'https://jobs.globalhuntindia.com/',
  mailTemplatekey: 'CandidateMailTemplate.html',
  internal: 'http://isearch.globalhuntindia.com',
  dialer: 'https://dialer.globalhuntindia.com',
  crm: 'https://hitech.globalhuntindia.com:92',
  analytics: 'http://analytics.globalhuntindia.com',
  refCheck: 'https://refcheck.globalhuntindia.com'
};
